@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    color: #25354b;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
}